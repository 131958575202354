import * as React from "react";

const PageNotFound = () => {
  return (
    <div>
      What are you doing here? <a href="/">Go back to the lounge...</a>
    </div>
  );
};

export default PageNotFound;
